<template>
    <!-- eslint-disable max-len -->
    <div @click="toggleProductDetailDrawer" class="relative flex flex-col p-2 rounded-lg cursor-pointer OrderProductGridItem bg-day-01dp dark:bg-night-16dp shadow-grid"
        :class="{
            'opacity-50 cursor-not-allowed': orderStatus === 'processing' && orderProduct.product !== product.id
                || tapAndGoEnabled && balance < (slotData.price + (slotData.deposit || 0))
                || machineStatus === 'unavailable'
                || machineStatus === 'busy'
                || showUnavailableMessage
        }">
        <div class="py-4 bg-white rounded OrderProductGridItem_Image">
            <img :src="product.image" :alt="product.name" class="absolute inset-0 object-cover h-full p-2 mx-auto rounded" />
        </div>

        <div class="mt-2 OrderProductGridItem_ProductInfo">
            <span class="block font-medium">{{ product.name }}</span>

						<div class="inline-flex space-x-1">
							<span
								v-if="tapAndGoEnabled && !machine.has_free_vending"
								class="block font-bold text-day-on-surface-ultra-emphasis dark:text-night-on-surface-ultra-emphasis">

								{{ currency(slotData.price) }}
							</span>

							<span
								v-else
								class="block font-bold text-day-on-surface-ultra-emphasis dark:text-night-on-surface-ultra-emphasis">

								{{ slotData.price == 0 ? $t('order.free') : currency(slotData.price) }}
							</span>

							<span v-if="slotData.deposit && slotData.deposit > 0" class="text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled">
								{{ $t('order.excl-deposit') }}
							</span>
						</div>
        </div>

         <!-- Tap and go button -->
        <button
            v-if="tapAndGoEnabled && balance >= (slotData.price + (slotData.deposit || 0)) || tapAndGoEnabled && machine.has_free_vending "
            @click.stop="orderTappedProduct({
                slot: slotData.slot,
                product: product.id,
                amount: 1,
            })"
            :disabled="orderStatus === 'processing' || machineStatus === 'unavailable' || machineStatus === 'busy' || showUnavailableMessage"
            class="absolute p-2 rounded-full OrderProductGridItem_TapAndGo bg-primary-brand shadow-glow-brand-button">

            <AppIcon v-if="orderProduct && orderProduct.product === product.id && orderStatus === 'processing'" icon-name="processing" :spin="true" class="text-white" key="tap-and-go-order-processing" />
            <AppIcon v-else-if="orderProduct && orderProduct.product === product.id && orderStatus === 'success'" icon-name="check" class="text-white" key="tap-and-go-order-success" />
            <AppIcon v-else icon-name="fast-buy" class="text-white" key="tap-and-go-order" />
        </button>

        <!-- Default add to cart button -->
        <button
            v-if="!tapAndGoEnabled"
            @click.stop="addProductToCart(slotData.slot, product.id)"
            :disabled="hasReachedMax || machineStatus === 'unavailable' || machineStatus === 'busy' || showUnavailableMessage"
            :class="{ 'opacity-50 cursor-not-allowed': hasReachedMax }"
            class="absolute p-2 rounded-full OrderProductGridItem_AddToCart bg-primary-brand shadow-glow-brand-button">

            <AppIcon icon-name="plus" class="text-white" key="default-order" />
        </button>

        <portal to="drawers">
            <ProductDetailDrawer
                v-if="showProductDetailDrawer"
                :slot-data="slotData"
                :product="product"
                :allow-add="!hasReachedMax"
                @add-to-cart="addProductToCart(slotData.slot, product.id)"
                @close="toggleProductDetailDrawer" />
        </portal>
    </div>
</template>

<script>
import ProductDetailDrawer from '@/components/Drawers/ProductDetail.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import { ref, computed } from '@vue/composition-api';
import useOrder from '@/composables/useOrder';
import useFilters from '@/filters';
import useWallet from '@/composables/useWallet';
import useTapAndgo from '@/composables/useTapAndGo';
import useMachine from '@/composables/useMachine';

export default {
    name: 'OrderProductGridItem',

    props: {
        slotData: {
            type: Object,
            required: true,
        },
    },

    components: {
        ProductDetailDrawer,
        AppIcon,
    },

    setup(props) {
        const { machineStatus, showUnavailableMessage, machine } = useMachine();
        const { cart, addProductToCart } = useOrder();
        const { balance } = useWallet();
        const { currency } = useFilters();
        const {
            enabled: tapAndGoEnabled,
            orderTappedProduct,
            orderStatus,
            orderProduct,
        } = useTapAndgo();

        const showProductDetailDrawer = ref(false);

        /**
         * Method to toggle the product detail drawer
         * @returns {void}
         */
        const toggleProductDetailDrawer = () => {
            showProductDetailDrawer.value = !showProductDetailDrawer.value;
        };

        /**
         * Computed property to check if cart amount has reached stock limit
         * @returns {Boolean}
         */
        const hasReachedMax = computed(() => {
            if (!cart.value || cart.value.length === 0) return false;

            const cartItem = cart.value.find((item) => item.slot === props.slotData.slot);

            // Check item with same slot ID is present in cart
            if (cartItem) {
                return cartItem.amount >= props.slotData.stock;
            }

            return false;
        });

        return {
            product: props.slotData.product,
            currency,
            addProductToCart,
            showProductDetailDrawer,
            toggleProductDetailDrawer,
            hasReachedMax,
            tapAndGoEnabled,
            orderTappedProduct,
            orderStatus,
            orderProduct,
            balance,
            machineStatus,
            showUnavailableMessage,
            machine,
        };
    },
};
</script>

<style lang="scss" scoped>
.OrderProductGridItem {

    &_Image {
        padding-top: 75%;
        position: relative;
    }

    &_AddToCart, &_TapAndGo {
        top: 16px;
        right: 16px;
    }
}
</style>
